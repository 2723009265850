import { NextSeo } from 'next-seo';
import Configurator from '../src/components/Configurator';

export default function Home({ startingConfiguration }) {
  return (
    <>
      <NextSeo title="Home" />
      <Configurator startingConfiguration={startingConfiguration} />
    </>
  );
}

export async function getStaticProps(context) {
  return {
    props: {
      startingConfiguration: [],
    },
  };
}
